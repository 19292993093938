const URL = 'https://data-api-2.omniflix.studio';
const UPLOAD_URL = 'https://main-ipfs.omniflix.studio';
const STUDIO_URL = 'https://api.omniflix.studio';

export const COLLECTIONS_URL = (skip, limit, sortBy, order, searchKey, isFeatured) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (isFeatured) {
        params.push('featured=true');
    }

    return `${URL}/collections?${params.join('&')}`;
};
export const DENOM_URL = (value, skip, limit, sortBy, order, searchKey) => {
    const params = [`onLaunchpad=${value}`];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/collections?${params.join('&')}`;
};

export const ALL_DENOM_URL = (value, skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/collections?${params.join('&')}`;
};
export const urlCollectionUpdate = (ID) => `${URL}/collections/${ID}`;
export const urlCollection = (id) => {
    return `${URL}/collections/${id}`;
};

export const CAMPAIGNS_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/campaigns?${params.join('&')}`;
};

export const urlAddCampaign = () => `${URL}/campaigns`;
export const urlSingleCampaign = (ID) => `${URL}/campaigns/${ID}`;

export const USER_LOGIN_URL = `${URL}/login`;
export const USER_LOGIN_STUDIO_URL = `${STUDIO_URL}/admin/login`;
export const USER_ACCESS_TOKEN = `${URL}/refresh-token`;

export const ITC_CAMPAIGNS_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/itc-campaigns?${params.join('&')}`;
};

export const urlITCCampaignUpdate = (ID) => `${URL}/itc-campaigns/${ID}`;

export const BANNER_URL = (type, statuses) => {
    const params = [];
    if (type) {
        params.push(`type=${type}`);
    }
    if (statuses) {
        params.push(`statuses=${statuses}`);
    }

    return `${URL}/banners?${params.join('&')}`;
};

export const urlAddBanner = () => `${URL}/banners`;
export const urlSingleBanner = (ID) => `${URL}/banners/${ID}`;

export const NETOWKRS_LIST_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = ['all=true'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${STUDIO_URL}/networks?${params.join('&')}`;
};
export const VERIFIED_NETOWKRS_LIST_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = ['isVerified=true'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${STUDIO_URL}/networks?${params.join('&')}`;
};
export const urlUpdateNetwork = (ID) => `${STUDIO_URL}/networks/${ID}`;
export const FEATURES_LIST_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${STUDIO_URL}/admin/features?${params.join('&')}`;
};

export const IMAGE_UPLOAD_URL = `${UPLOAD_URL}/api/v0/add?pin=true&rap-with-directory=false&progress=false`;
export const urlUpdateFeature = (ID) => `${STUDIO_URL}/admin/features/${ID}`;

// iv list
export const IV_LIST_URL = (skip, limit, sortBy, order, searchKey, isBanner) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (isBanner) {
        params.push('isBanner=true');
    }

    return `${STUDIO_URL}/tv/interactive-videos?${params.join('&')}`;
};
export const urlIVUpdate = (ID) => `${STUDIO_URL}/admin/interactive-videos/${ID}`;

// FEATURED DROPS
export const FEATURED_DROPS_URL = (skip, limit, sortBy, order, searchKey) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`searchKey=${searchKey}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${URL}/minter-contracts?${params.join('&')}`;
};
export const urlUpdateFeaturedDrops = (contractAddress) => `${URL}/minter-contracts/${contractAddress}`;
