export const COLLECTIONS_FETCH_IN_PROGRESS = 'COLLECTIONS_FETCH_IN_PROGRESS';
export const COLLECTIONS_FETCH_SUCCESS = 'COLLECTIONS_FETCH_SUCCESS';
export const COLLECTIONS_FETCH_ERROR = 'COLLECTIONS_FETCH_ERROR';

export const MINT_ACCESS_ADDRESS_SET = 'MINT_ACCESS_ADDRESS_SET';
export const MINT_ACCESS_DIALOG_SHOW = 'MINT_ACCESS_DIALOG_SHOW';
export const MINT_ACCESS_DIALOG_HIDE = 'MINT_ACCESS_DIALOG_HIDE';

export const FEATURED_POSITION_SET = 'FEATURED_POSITION_SET';
export const FEATURED_POSITION_DIALOG_SHOW = 'FEATURED_POSITION_DIALOG_SHOW';
export const FEATURED_POSITION_DIALOG_HIDE = 'FEATURED_POSITION_DIALOG_HIDE';
export const SHOW_FEATURED_SET = 'SHOW_FEATURED_SET';

export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS';
export const COLLECTION_UPDATE_IN_PROGRESS = 'COLLECTION_UPDATE_IN_PROGRESS';
export const COLLECTION_UPDATE_ERROR = 'COLLECTION_UPDATE_ERROR';

export const LAUNCHPAD_URL_SET = 'LAUNCHPAD_URL_SET';
export const DENOM_ID_SET = 'DENOM_ID_SET';
export const LAUNCHPAD_DIALOG_SHOW = 'LAUNCHPAD_DIALOG_SHOW';
export const LAUNCHPAD_DIALOG_HIDE = 'LAUNCHPAD_DIALOG_HIDE';

export const COLLECTION_FETCH_IN_PROGRESS = 'COLLECTION_FETCH_IN_PROGRESS';
export const COLLECTION_FETCH_SUCCESS = 'COLLECTION_FETCH_SUCCESS';
export const COLLECTION_FETCH_ERROR = 'COLLECTION_FETCH_ERROR';

export const ITC_CAMPAIGNS_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_FETCH_SUCCESS = 'ITC_CAMPAIGNS_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_FETCH_ERROR = 'ITC_CAMPAIGNS_FETCH_ERROR';

export const ITC_CAMPAIGN_UPDATE_SUCCESS = 'ITC_CAMPAIGN_UPDATE_SUCCESS';
export const ITC_CAMPAIGN_UPDATE_IN_PROGRESS = 'ITC_CAMPAIGN_UPDATE_IN_PROGRESS';
export const ITC_CAMPAIGN_UPDATE_ERROR = 'ITC_CAMPAIGN_UPDATE_ERROR';

export const BANNER_FETCH_IN_PROGRESS = 'BANNER_FETCH_IN_PROGRESS';
export const BANNER_FETCH_SUCCESS = 'BANNER_FETCH_SUCCESS';
export const BANNER_FETCH_ERROR = 'BANNER_FETCH_ERROR';

export const BANNER_LIST_ADD_SUCCESS = 'CAMPAIGN_LIST_ADD_SUCCESS';
export const BANNER_LIST_ADD_IN_PROGRESS = 'CAMPAIGN_LIST_ADD_IN_PROGRESS';
export const BANNER_LIST_ADD_ERROR = 'CAMPAIGN_LIST_ADD_ERROR';

export const BANNER_UPDATE_IN_PROGRESS = 'BANNER_UPDATE_IN_PROGRESS';
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS';
export const BANNER_UPDATE_ERROR = 'BANNER_UPDATE_ERROR';

export const BANNER_DELETE_IN_PROGRESS = 'BANNER_DELETE_IN_PROGRESS';
export const BANNER_DELETE_SUCCESS = 'BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_ERROR = 'BANNER_DELETE_ERROR';

export const BANNER_LIST_DIALOG_SHOW = 'BANNER_LIST_DIALOG_SHOW';
export const BANNER_LIST_DIALOG_HIDE = 'BANNER_LIST_DIALOG_HIDE';

export const DELETE_BANNER_DIALOG_SHOW = 'DELETE_BANNER_DIALOG_SHOW';
export const DELETE_BANNER_DIALOG_HIDE = 'DELETE_BANNER_DIALOG_HIDE';

export const BANNER_QUICK_VIEW_SHOW = 'BANNER_QUICK_VIEW_SHOW';
export const BANNER_QUICK_VIEW_HIDE = 'BANNER_QUICK_VIEW_HIDE';

export const BANNER_NAME_SET = 'BANNER_NAME_SET';
export const BANNER_START_DATE_SET = 'BANNER_START_DATE_SET';
export const BANNER_END_DATE_SET = 'BANNER_END_DATE_SET';
export const BANNER_TYPE_SET = 'BANNER_TYPE_SET';
export const BANNER_REDIRECTION_URL_SET = 'BANNER_REDIRECTION_URL_SET';

export const MOBILE_IMAGE_URL_SET = 'MOBILE_IMAGE_URL_SET';
export const MOBILE_IMAGE_UPLOAD_IN_PROGRESS = 'MOBILE_IMAGE_UPLOAD_IN_PROGRESS';
export const MOBILE_IMAGE_UPLOAD_SUCCESS = 'MOBILE_IMAGE_UPLOAD_SUCCESS';
export const MOBILE_IMAGE_UPLOAD_ERROR = 'MOBILE_IMAGE_UPLOAD_ERROR';

export const WEB_IMAGE_URL_SET = 'WEB_IMAGE_URL_SET';
export const WEB_IMAGE_UPLOAD_IN_PROGRESS = 'WEB_IMAGE_UPLOAD_IN_PROGRESS';
export const WEB_IMAGE_UPLOAD_SUCCESS = 'WEB_IMAGE_UPLOAD_SUCCESS';
export const WEB_IMAGE_UPLOAD_ERROR = 'WEB_IMAGE_UPLOAD_ERROR';

export const TAB_IMAGE_URL_SET = 'TAB_IMAGE_URL_SET';
export const TAB_IMAGE_UPLOAD_IN_PROGRESS = 'TAB_IMAGE_UPLOAD_IN_PROGRESS';
export const TAB_IMAGE_UPLOAD_SUCCESS = 'TAB_IMAGE_UPLOAD_SUCCESS';
export const TAB_IMAGE_UPLOAD_ERROR = 'TAB_IMAGE_UPLOAD_ERROR';

export const LARGE_SCREEN_IMAGE_URL_SET = 'LARGE_SCREEN_IMAGE_URL_SET';
export const LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS = 'LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS';
export const LARGE_SCREEN_UPLOAD_SUCCESS = 'LARGE_SCREEN_IMAGE_UPLOAD_SUCCESS';
export const LARGE_SCREEN_IMAGE_UPLOAD_ERROR = 'LARGE_SCREEN_IMAGE_UPLOAD_ERROR';

export const STATUS_SWITCH_SET = 'STATUS_SWITCH_SET';

export const FEATURED_DROPS_FETCH_SUCCESS = 'FEATURED_DROPS_FETCH_SUCCESS';
export const FEATURED_DROPS_FETCH_IN_PROGRESS = 'FEATURED_DROPS_FETCH_IN_PROGRESS';
export const FEATURED_DROPS_FETCH_ERROR = 'FEATURED_DROPS_FETCH_ERROR';

export const UPDATE_FEATURED_DROPS_SUCCESS = 'UPDATE_FEATURED_DROPS_SUCCESS';
export const UPDATE_FEATURED_DROPS_IN_PROGRESS = 'UPDATE_FEATURED_DROPS_IN_PROGRESS';
export const UPDATE_FEATURED_DROPS_ERROR = 'UPDATE_FEATURED_DROPS_ERROR';

export const UPDATE_FEATURED_DROPS_DIALOG_SHOW = 'UPDATE_FEATURED_DROPS_DIALOG_SHOW';
export const UPDATE_FEATURED_DROPS_DIALOG_HIDE = 'UPDATE_FEATURED_DROPS_DIALOG_HIDE';
