import React from 'react';
import SelectMulti from 'react-select';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchVerifiedNetworks, setNetworkValueFeature } from '../../../../../actions/studio';

class DenomsSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showGif: false,
            denomsList: props.value || [],
        };

        // this.scrollDown = this.scrollDown.bind(this);
        this.handleChangeMulti = this.handleChangeMulti.bind(this);
        this.loadMoreNetworks = this.loadMoreNetworks.bind(this);
    }

    handleChangeMulti (selectedOptions) {
        this.setState({ denomsList: selectedOptions });
        this.props.setNetworkValueFeature(selectedOptions);
    }

    loadMoreNetworks () {
        const { inProgress, total, list, fetchVerifiedNetworks, limit } = this.props;

        if (inProgress || list.length >= total) { return; } // Prevent unnecessary API calls

        fetchVerifiedNetworks(list.length, limit || 10);
    }

    render () {
        const selectStyles = {};
        const options =
    this.props.list
        ? this.props.list.map((suggestion) => ({
            value: suggestion._id,
            label: (suggestion.name || suggestion.chain_name), // Use `name` instead of `chain_name`
        }))
        : [];

        const selectedValues = this.state.denomsList
            ? this.state.denomsList.map((item) => ({
                value: item.value || item._id,
                label: item.label || item.chain_name,
            }))
            : [];

        return (
            <div className="denom_select_field">
                <SelectMulti
                    isMulti
                    className={this.state.denomsList && this.state.denomsList.length > 0
                        ? 'title_list_field' : 'denoms_list title_list_field'}
                    options={options}
                    placeholder="Select Networks"
                    showGif={this.state.showGif}
                    styles={selectStyles}
                    textFieldProps={{
                        label: 'Director(s)',
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    value={selectedValues}
                    onChange={(e) => this.handleChangeMulti(e)}
                    onMenuScrollToBottom={this.loadMoreNetworks}
                    // onMenuScrollToBottom={this.scrollDown}
                />
            </div>
        );
    }
}

DenomsSelectField.propTypes = {
    fetchVerifiedNetworks: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    setNetworkValueFeature: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    value: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.studio.featureNetworkValue,

        inProgress: state.studio.verifiedNetworksList.inProgress,
        list: state.studio.verifiedNetworksList.list,
        total: state.studio.verifiedNetworksList.count,
        skip: state.studio.verifiedNetworksList.skip,
        limit: state.studio.verifiedNetworksList.limit,
    };
};

const actionToProps = {
    setNetworkValueFeature,
    fetchVerifiedNetworks,
};

export default connect(stateToProps, actionToProps)(DenomsSelectField);
