import React from 'react';
import './index.css';
import Table from './Table';

const Features = () => {
    return (
        <div className="collection_table features_table">
            <Table/>
        </div>
    );
};

export default Features;
