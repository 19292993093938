import React, { useState } from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchNetworks, hideVerifyDialog, updateNetwork } from '../../../../../actions/studio';
import CircularProgress from '../../../../../components/CircularProgress';

const VerifyDialog = (props) => {
    const [loader, setLoader] = useState(false);
    const handleClick = () => {
        setLoader(true);
        const id = props.value && props.value._id;
        const verification = props.type !== 'unverify';
        const data = {
            id: id,
            isVerified: verification,
        };

        props.updateNetwork(id, data, (cb) => {
            if (!cb) {
                setLoader(false);
                props.fetchNetworks(props.skip, props.limit, props.sortBy, props.order, props.searchKey);
                props.hideVerifyDialog();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog verify_dialog"
            open={props.open}
            onClose={props.hideVerifyDialog}>
            <div className="mint_access">
                <h2>Confirm {props.type === 'verify' ? ' Verify' : ' Un-Verify'} </h2>
                <Button disabled={loader} onClick={handleClick}>
                    {loader && <CircularProgress />}
                    Confirm
                </Button>
            </div>
        </Dialog>
    );
};

VerifyDialog.propTypes = {
    fetchNetworks: PropTypes.func.isRequired,
    hideVerifyDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    sortBy: PropTypes.string.isRequired,
    updateNetwork: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    limit: PropTypes.number,
    order: PropTypes.string,
    searchKey: PropTypes.string,
    skip: PropTypes.number,
    type: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.studio.verifyDialog.open,
        value: state.studio.verifyDialog.value,
        type: state.studio.verifyDialog.verify,
        skip: state.studio.networksList.skip,
        limit: state.studio.networksList.limit,
        sortBy: state.studio.networksList.sortBy,
        order: state.studio.networksList.order,
        searchKey: state.studio.networksList.searchKey,
    };
};

const actionsToProps = {
    hideVerifyDialog,
    fetchNetworks,
    updateNetwork,
};

export default connect(stateToProps, actionsToProps)(VerifyDialog);
