import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import { connect } from 'react-redux';
import CircularProgress from '../../../../components/CircularProgress';
import withRouter from '../../../../components/WithRouter';
import { fetchFeatures, showUpdateFeatureDialog } from '../../../../actions/studio';
import { Button } from '@mui/material';

const Table = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeatures(props.limit * currentPage, props.limit, props.sortBy, props.order);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeatures(props.skip, numberOfRows, props.sortBy, props.order);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeatures(props.skip, props.limit, changedColumn, direction);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
        search: false,
    };

    const columns = [{
        type: 'code_id',
        label: 'Code ID',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'is_active',
        label: 'Active',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'supported_networks',
        label: 'Supported Networks',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value && value.length}
                    </div>
                );
            },
        },
    }, {
        name: 'action',
        label: 'Action',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        <Button onClick={() => props.showUpdateFeatureDialog(value)}>
                                Update
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.code_id,
                item.is_active ? 'true' : 'false',
                item.supported_networks,
                item,
            ],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

Table.propTypes = {
    fetchFeatures: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    showUpdateFeatureDialog: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    limit: PropTypes.number,
    order: PropTypes.string,
    skip: PropTypes.number,
    total: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.studio.featuresList.inProgress,
        list: state.studio.featuresList.list,
        total: state.studio.featuresList.count,
        skip: state.studio.featuresList.skip,
        limit: state.studio.featuresList.limit,
        sortBy: state.studio.featuresList.sortBy,
        order: state.studio.featuresList.order,
    };
};

const actionToProps = {
    fetchFeatures,
    showUpdateFeatureDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(Table));
