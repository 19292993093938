import React, { useState } from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchFeaturedDrops, hidepdateFeaturedDropsDialog, updateFeaturedDrops } from '../../../../../actions/home';
import CircularProgress from '../../../../../components/CircularProgress';

const VerifyDialog = (props) => {
    const [loader, setLoader] = useState(false);
    const handleClick = () => {
        setLoader(true);
        const contractAddress = props.value && props.value.minter_contract_address;
        const verify = props.type !== 'unverify';
        const data = {
            address: contractAddress,
            featured: verify,
        };

        props.updateFeaturedDrops(contractAddress, data, (cb) => {
            if (!cb) {
                setLoader(false);
                props.fetchFeaturedDrops(props.skip, props.limit);
                props.hideVerifyDialog();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog verify_dialog"
            open={props.open}
            onClose={props.hideVerifyDialog}>
            <div className="mint_access">
                <h2>Confirm {props.type === 'verify' ? ' Verify' : ' Un-Verify'} </h2>
                <Button disabled={loader} onClick={handleClick}>
                    {loader && <CircularProgress />}
                    Confirm
                </Button>
            </div>
        </Dialog>
    );
};

VerifyDialog.propTypes = {
    fetchFeaturedDrops: PropTypes.func.isRequired,
    hideVerifyDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    updateFeaturedDrops: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    limit: PropTypes.number,
    skip: PropTypes.number,
    type: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.updateFeaturedDropsDialog.open,
        value: state.home.updateFeaturedDropsDialog.value,
        type: state.home.updateFeaturedDropsDialog.verify,
        skip: state.home.featuredDrops.skip,
        limit: state.home.featuredDrops.limit,
    };
};

const actionsToProps = {
    hideVerifyDialog: hidepdateFeaturedDropsDialog,
    fetchFeaturedDrops,
    updateFeaturedDrops,
};

export default connect(stateToProps, actionsToProps)(VerifyDialog);
