import {
    COLLECTIONS_URL,
    ITC_CAMPAIGNS_URL,
    urlCollection,
    urlCollectionUpdate,
    urlITCCampaignUpdate,
    urlAddBanner,
    urlSingleBanner,
    BANNER_URL,
    IMAGE_UPLOAD_URL,
    FEATURED_DROPS_URL,
    urlUpdateFeaturedDrops,
} from '../constants/url';
import Axios from 'axios';
import {
    COLLECTION_FETCH_ERROR,
    COLLECTION_FETCH_IN_PROGRESS,
    COLLECTION_FETCH_SUCCESS,
    COLLECTION_UPDATE_ERROR,
    COLLECTION_UPDATE_IN_PROGRESS,
    COLLECTION_UPDATE_SUCCESS,
    COLLECTIONS_FETCH_ERROR,
    COLLECTIONS_FETCH_IN_PROGRESS,
    COLLECTIONS_FETCH_SUCCESS,
    DENOM_ID_SET,
    FEATURED_POSITION_DIALOG_HIDE,
    FEATURED_POSITION_DIALOG_SHOW,
    FEATURED_POSITION_SET,
    ITC_CAMPAIGN_UPDATE_ERROR,
    ITC_CAMPAIGN_UPDATE_IN_PROGRESS,
    ITC_CAMPAIGN_UPDATE_SUCCESS,
    ITC_CAMPAIGNS_FETCH_ERROR,
    ITC_CAMPAIGNS_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_FETCH_SUCCESS,
    LAUNCHPAD_DIALOG_HIDE,
    LAUNCHPAD_DIALOG_SHOW,
    LAUNCHPAD_URL_SET,
    MINT_ACCESS_ADDRESS_SET,
    MINT_ACCESS_DIALOG_HIDE,
    MINT_ACCESS_DIALOG_SHOW,
    SHOW_FEATURED_SET,
    BANNER_FETCH_IN_PROGRESS,
    BANNER_FETCH_SUCCESS,
    BANNER_FETCH_ERROR,
    BANNER_LIST_ADD_IN_PROGRESS,
    BANNER_LIST_ADD_SUCCESS,
    BANNER_LIST_ADD_ERROR,
    BANNER_UPDATE_IN_PROGRESS,
    BANNER_UPDATE_SUCCESS,
    BANNER_UPDATE_ERROR,
    BANNER_DELETE_IN_PROGRESS,
    BANNER_DELETE_SUCCESS,
    BANNER_DELETE_ERROR,
    BANNER_LIST_DIALOG_SHOW,
    BANNER_LIST_DIALOG_HIDE,
    BANNER_NAME_SET,
    BANNER_START_DATE_SET,
    BANNER_END_DATE_SET,
    DELETE_BANNER_DIALOG_HIDE,
    DELETE_BANNER_DIALOG_SHOW,
    BANNER_TYPE_SET,
    BANNER_QUICK_VIEW_SHOW,
    BANNER_QUICK_VIEW_HIDE,
    MOBILE_IMAGE_UPLOAD_IN_PROGRESS,
    MOBILE_IMAGE_UPLOAD_SUCCESS,
    MOBILE_IMAGE_UPLOAD_ERROR,
    MOBILE_IMAGE_URL_SET,
    WEB_IMAGE_UPLOAD_IN_PROGRESS,
    WEB_IMAGE_UPLOAD_SUCCESS,
    WEB_IMAGE_UPLOAD_ERROR,
    TAB_IMAGE_UPLOAD_IN_PROGRESS,
    TAB_IMAGE_UPLOAD_SUCCESS,
    TAB_IMAGE_UPLOAD_ERROR,
    LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS,
    LARGE_SCREEN_UPLOAD_SUCCESS,
    LARGE_SCREEN_IMAGE_UPLOAD_ERROR,
    WEB_IMAGE_URL_SET,
    TAB_IMAGE_URL_SET,
    LARGE_SCREEN_IMAGE_URL_SET,
    STATUS_SWITCH_SET,
    BANNER_REDIRECTION_URL_SET,
    FEATURED_DROPS_FETCH_IN_PROGRESS,
    FEATURED_DROPS_FETCH_SUCCESS,
    FEATURED_DROPS_FETCH_ERROR,
    UPDATE_FEATURED_DROPS_IN_PROGRESS,
    UPDATE_FEATURED_DROPS_SUCCESS,
    UPDATE_FEATURED_DROPS_ERROR,
    UPDATE_FEATURED_DROPS_DIALOG_SHOW,
    UPDATE_FEATURED_DROPS_DIALOG_HIDE,
} from '../constants/home';
import { handleErrorMessage } from '../utils/errorMessages';

export const showMintAccessDialog = (value) => {
    return {
        type: MINT_ACCESS_DIALOG_SHOW,
        value,
    };
};

export const hideMintAccessDialog = () => {
    return {
        type: MINT_ACCESS_DIALOG_HIDE,
    };
};

export const setMintAccessAddress = (value) => {
    return {
        type: MINT_ACCESS_ADDRESS_SET,
        value,
    };
};

export const showFeaturedPositionDialog = (value) => {
    return {
        type: FEATURED_POSITION_DIALOG_SHOW,
        value,
    };
};

export const hideFeaturedPositionDialog = () => {
    return {
        type: FEATURED_POSITION_DIALOG_HIDE,
    };
};

export const setFeaturedPosition = (value) => {
    return {
        type: FEATURED_POSITION_SET,
        value,
    };
};

export const setShowFeatured = (value) => {
    return {
        type: SHOW_FEATURED_SET,
        value,
    };
};

const fetchCollectionsInProgress = () => {
    return {
        type: COLLECTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchCollectionsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: COLLECTIONS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchCollectionsError = (message) => {
    return {
        type: COLLECTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchCollections = (skip, limit, sortBy, order, searchKey, isFeatured) => (dispatch) => {
    dispatch(fetchCollectionsInProgress());

    const url = COLLECTIONS_URL(skip, limit, sortBy, order, searchKey, isFeatured);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchCollectionsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchCollectionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateCollectionInProgress = () => {
    return {
        type: COLLECTION_UPDATE_IN_PROGRESS,
    };
};

const updateCollectionSuccess = (value, message) => {
    return {
        type: COLLECTION_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateCollectionError = (message) => {
    return {
        type: COLLECTION_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateCollection = (id, data, cb) => (dispatch) => {
    dispatch(updateCollectionInProgress());

    const url = urlCollectionUpdate(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(updateCollectionSuccess(res.data && res.data.result, 'Collection Tag Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateCollectionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

export const showLaunchpadDialog = (value) => {
    return {
        type: LAUNCHPAD_DIALOG_SHOW,
        value,
    };
};

export const hideLaunchpadDialog = () => {
    return {
        type: LAUNCHPAD_DIALOG_HIDE,
    };
};

export const setLaunchpadUrl = (value) => {
    return {
        type: LAUNCHPAD_URL_SET,
        value,
    };
};

export const setDenomId = (value) => {
    return {
        type: DENOM_ID_SET,
        value,
    };
};

const fetchCollectionInProgress = () => {
    return {
        type: COLLECTION_FETCH_IN_PROGRESS,
    };
};

const fetchCollectionSuccess = (items) => {
    return {
        type: COLLECTION_FETCH_SUCCESS,
        items: items,
    };
};

const fetchCollectionError = (message) => {
    return {
        type: COLLECTION_FETCH_ERROR,
        message,
    };
};

export const fetchCollection = (id, cb) => (dispatch) => {
    dispatch(fetchCollectionInProgress());

    const url = urlCollection(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result;
            dispatch(fetchCollectionSuccess(items));
            cb(items);
        })
        .catch((error) => {
            dispatch(fetchCollectionError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchITCCampaignsInProgress = () => {
    return {
        type: ITC_CAMPAIGNS_FETCH_IN_PROGRESS,
    };
};

const fetchITCCampaignsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: ITC_CAMPAIGNS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchITCCampaignsError = (message) => {
    return {
        type: ITC_CAMPAIGNS_FETCH_ERROR,
        message,
    };
};

export const fetchITCCampaigns = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchITCCampaignsInProgress());

    const url = ITC_CAMPAIGNS_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchITCCampaignsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchITCCampaignsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateITCCampaignInProgress = () => {
    return {
        type: ITC_CAMPAIGN_UPDATE_IN_PROGRESS,
    };
};

const updateITCCampaignSuccess = (value, message) => {
    return {
        type: ITC_CAMPAIGN_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateITCCampaignError = (message) => {
    return {
        type: ITC_CAMPAIGN_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateITCCampaign = (id, data, cb) => (dispatch) => {
    dispatch(updateITCCampaignInProgress());

    const url = urlITCCampaignUpdate(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(updateITCCampaignSuccess(res.data && res.data.result, 'ITC Campaign Tag Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateITCCampaignError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const fetchBannerInProgress = () => {
    return {
        type: BANNER_FETCH_IN_PROGRESS,
    };
};

const fetchBannerSuccess = (value, types, statuses) => {
    return {
        type: BANNER_FETCH_SUCCESS,
        value,
        types,
        statuses,
        variant: 'success',
    };
};

const fetchBannerError = (message) => {
    return {
        type: BANNER_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchBanners = (types, statuses) => (dispatch) => {
    dispatch(fetchBannerInProgress());

    const url = BANNER_URL(types, statuses);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(fetchBannerSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchBannerError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const addBannerListInProgress = () => {
    return {
        type: BANNER_LIST_ADD_IN_PROGRESS,
    };
};

const addBannerListSuccess = (value, message) => {
    return {
        type: BANNER_LIST_ADD_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const addBannerListError = (message) => {
    return {
        type: BANNER_LIST_ADD_ERROR,
        message,
        variant: 'error',
    };
};

export const addBannerList = (data, cb) => (dispatch) => {
    dispatch(addBannerListInProgress());

    const url = urlAddBanner();
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(addBannerListSuccess(res.data && res.data.result, 'Banner Added Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(addBannerListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const updateBannerListInProgress = () => {
    return {
        type: BANNER_UPDATE_IN_PROGRESS,
    };
};

const updateBannerListSuccess = (value, message) => {
    return {
        type: BANNER_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateBannerListError = (message) => {
    return {
        type: BANNER_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateBannerList = (data, id, cb) => (dispatch) => {
    dispatch(updateBannerListInProgress());

    const url = urlSingleBanner(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(updateBannerListSuccess(res.data && res.data.result, 'Banner Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateBannerListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const deleteBannerListInProgress = () => {
    return {
        type: BANNER_DELETE_IN_PROGRESS,
    };
};

const deleteBannerListSuccess = (value, message) => {
    return {
        type: BANNER_DELETE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const deleteBannerListError = (message) => {
    return {
        type: BANNER_DELETE_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteBannerList = (id, cb) => (dispatch) => {
    dispatch(deleteBannerListInProgress());

    const url = urlSingleBanner(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(deleteBannerListSuccess(res.data && res.data.result, 'Banner Deleted Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(deleteBannerListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

export const showBannerListDialog = (value) => {
    return {
        type: BANNER_LIST_DIALOG_SHOW,
        value,
    };
};

export const hideBannerListDialog = () => {
    return {
        type: BANNER_LIST_DIALOG_HIDE,
    };
};

export const showDeleteBannerDialog = (value) => {
    return {
        type: DELETE_BANNER_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteBannerDialog = () => {
    return {
        type: DELETE_BANNER_DIALOG_HIDE,
    };
};

export const showBannerQuickView = (value) => {
    return {
        type: BANNER_QUICK_VIEW_SHOW,
        value,
    };
};

export const hideBannerQuickView = () => {
    return {
        type: BANNER_QUICK_VIEW_HIDE,
    };
};

export const setBannerName = (value) => {
    return {
        type: BANNER_NAME_SET,
        value,
    };
};

export const setBannerStartDate = (value) => {
    return {
        type: BANNER_START_DATE_SET,
        value,
    };
};

export const setBannerEndDate = (value) => {
    return {
        type: BANNER_END_DATE_SET,
        value,
    };
};

export const setBannerType = (value) => {
    return {
        type: BANNER_TYPE_SET,
        value,
    };
};

export const setBannerRedirectionUrl = (value) => {
    return {
        type: BANNER_REDIRECTION_URL_SET,
        value,
    };
};

export const setMobileImageUrl = (value) => {
    return {
        type: MOBILE_IMAGE_URL_SET,
        value,
    };
};

export const mobileImageUploadInProgress = () => {
    return {
        type: MOBILE_IMAGE_UPLOAD_IN_PROGRESS,
    };
};

export const mobileImageUploadSuceess = (value) => {
    return {
        type: MOBILE_IMAGE_UPLOAD_SUCCESS,
        value,
        variant: 'success',
    };
};

export const mobileImageUploadError = (message) => {
    return {
        type: MOBILE_IMAGE_UPLOAD_ERROR,
        message,
        variant: 'error',
    };
};

export const mobileImageUpload = (file, cb) => (dispatch) => {
    dispatch(mobileImageUploadInProgress());

    const formData = new FormData();
    formData.append('file', file);

    Axios.post(IMAGE_UPLOAD_URL, formData, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(mobileImageUploadSuceess(res.data));
            cb(res.data);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(mobileImageUploadError(message));

            cb(null);
        });
};

export const setWebImageUrl = (value) => {
    return {
        type: WEB_IMAGE_URL_SET,
        value,
    };
};

export const webImageUploadInProgress = () => {
    return {
        type: WEB_IMAGE_UPLOAD_IN_PROGRESS,
    };
};

export const webImageUploadSuceess = (value) => {
    return {
        type: WEB_IMAGE_UPLOAD_SUCCESS,
        value,
        variant: 'success',
    };
};

export const webImageUploadError = (message) => {
    return {
        type: WEB_IMAGE_UPLOAD_ERROR,
        message,
        variant: 'error',
    };
};

export const webImageUpload = (file, cb) => (dispatch) => {
    dispatch(webImageUploadInProgress());

    const formData = new FormData();
    formData.append('file', file);

    Axios.post(IMAGE_UPLOAD_URL, formData, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(webImageUploadSuceess(res.data));
            cb(res.data);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(webImageUploadError(message));

            cb(null);
        });
};

export const setTabImageUrl = (value) => {
    return {
        type: TAB_IMAGE_URL_SET,
        value,
    };
};

export const tabImageUploadInProgress = () => {
    return {
        type: TAB_IMAGE_UPLOAD_IN_PROGRESS,
    };
};

export const tabImageUploadSuceess = (value) => {
    return {
        type: TAB_IMAGE_UPLOAD_SUCCESS,
        value,
        variant: 'success',
    };
};

export const tabImageUploadError = (message) => {
    return {
        type: TAB_IMAGE_UPLOAD_ERROR,
        message,
        variant: 'error',
    };
};

export const tabImageUpload = (file, cb) => (dispatch) => {
    dispatch(tabImageUploadInProgress());

    const formData = new FormData();
    formData.append('file', file);

    Axios.post(IMAGE_UPLOAD_URL, formData, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(tabImageUploadSuceess(res.data));
            cb(res.data);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(tabImageUploadError(message));

            cb(null);
        });
};

export const setLargeScreenImageUrl = (value) => {
    return {
        type: LARGE_SCREEN_IMAGE_URL_SET,
        value,
    };
};

export const largeScreenImageUploadInProgress = () => {
    return {
        type: LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS,
    };
};

export const largeScreenImageUploadSuceess = (value) => {
    return {
        type: LARGE_SCREEN_UPLOAD_SUCCESS,
        value,
        variant: 'success',
    };
};

export const largeScreenImageUploadError = (message) => {
    return {
        type: LARGE_SCREEN_IMAGE_UPLOAD_ERROR,
        message,
        variant: 'error',
    };
};

export const largeScreenImageUpload = (file, cb) => (dispatch) => {
    dispatch(largeScreenImageUploadInProgress());

    const formData = new FormData();
    formData.append('file', file);

    Axios.post(IMAGE_UPLOAD_URL, formData, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(largeScreenImageUploadSuceess(res.data));
            cb(res.data);
        })
        .catch((error) => {
            const message = handleErrorMessage(error);
            dispatch(largeScreenImageUploadError(message));

            cb(null);
        });
};

export const setStatusSwitch = (value) => {
    return {
        type: STATUS_SWITCH_SET,
        value,
    };
};

//  FEATURED DROPS
const fetchFeaturedDropsInProgress = () => {
    return {
        type: FEATURED_DROPS_FETCH_IN_PROGRESS,
    };
};

const fetchFeaturedDropsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: FEATURED_DROPS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchFeaturedDropsError = (message) => {
    return {
        type: FEATURED_DROPS_FETCH_ERROR,
        message,
    };
};

export const fetchFeaturedDrops = (skip, limit, sortBy, order, searchKey, isFeatured) => (dispatch) => {
    dispatch(fetchFeaturedDropsInProgress());

    const url = FEATURED_DROPS_URL(skip, limit, sortBy, order, searchKey, isFeatured);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchFeaturedDropsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchFeaturedDropsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateFeaturedDropsInProgress = () => {
    return {
        type: UPDATE_FEATURED_DROPS_IN_PROGRESS,
    };
};

const updateFeaturedDropsSuccess = (value, message) => {
    return {
        type: UPDATE_FEATURED_DROPS_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateFeaturedDropsError = (message) => {
    return {
        type: UPDATE_FEATURED_DROPS_ERROR,
        message,
        variant: 'error',
    };
};

export const updateFeaturedDrops = (id, data, cb) => (dispatch) => {
    dispatch(updateFeaturedDropsInProgress());

    const url = urlUpdateFeaturedDrops(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(updateFeaturedDropsSuccess(res.data && res.data.result, 'Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateFeaturedDropsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

export const showUpdateFeaturedDropsDialog = (value, verify) => {
    return {
        type: UPDATE_FEATURED_DROPS_DIALOG_SHOW,
        value,
        verify,
    };
};

export const hidepdateFeaturedDropsDialog = () => {
    return {
        type: UPDATE_FEATURED_DROPS_DIALOG_HIDE,
    };
};
