import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { fetchFeatures, fetchNetworks, setStudioTabValue } from '../../../actions/studio';

const Tabs = (props) => {
    const handleChange = (newValue) => {
        props.setTabValue(newValue);
        if (newValue === 'networks' && props.networksList && !props.networksList.length && !props.networkInProgress) {
            props.fetchNetworks(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
        } else if (newValue === 'features' && props.featuresList && !props.featuresList.length && !props.featuresInProgress) {
            props.fetchFeatures(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
        }
    };
    // app.put('/itc-campaigns/:id', isAdmin,
    //     validation.updateCampaign, controller.updateCampaign);
    return (
        <div className="tabs">
            <Button
                className={props.value === 'ivs' ? 'active_tab' : ''}
                onClick={() => handleChange('ivs')}>
                IVs
            </Button>
            <Button
                className={props.value === 'networks' ? 'active_tab' : ''}
                onClick={() => handleChange('networks')}>
                Networks
            </Button>
            <Button
                className={props.value === 'features' ? 'active_tab' : ''}
                onClick={() => handleChange('features')}>
                Features
            </Button>
        </div>
    );
};

Tabs.propTypes = {
    featuresInProgress: PropTypes.bool.isRequired,
    featuresList: PropTypes.array.isRequired,
    fetchFeatures: PropTypes.func.isRequired,
    fetchNetworks: PropTypes.func.isRequired,
    networkInProgress: PropTypes.bool.isRequired,
    networksList: PropTypes.array.isRequired,
    setTabValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    featuresLimit: PropTypes.number,
    featuresSkip: PropTypes.number,
    networkLimit: PropTypes.number,
    networkSkip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.studio.studioTabValue,

        networksList: state.studio.networksList.list,
        networkInProgress: state.studio.networksList.inProgress,
        networkSkip: state.studio.networksList.skip,
        networkLimit: state.studio.networksList.limit,

        featuresList: state.studio.featuresList.list,
        featuresInProgress: state.studio.featuresList.inProgress,
        featuresSkip: state.studio.featuresList.skip,
        featuresLimit: state.studio.featuresList.limit,
    };
};

const actionToProps = {
    setTabValue: setStudioTabValue,
    fetchNetworks,
    fetchFeatures,
};

export default connect(stateToProps, actionToProps)(Tabs);
