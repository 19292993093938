import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NetworkSelectField from './NetworkSelectField';
import { showMessage } from '../../../../../actions/snackbar';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../config';
import CircularProgress from '../../../../../components/CircularProgress';
import { fetchFeatures, fetchNetworks, fetchVerifiedNetworks, hideUpdateFeatureDialog, setNetworkValueFeature, updateFeature } from '../../../../../actions/studio';

const UpdateFeatureDialog = (props) => {
    const [progress, setProgress] = useState(false);
    useEffect(() => {
        if (props.value && props.value.supported_networks) {
            props.setNetworkValueFeature(props.value.supported_networks);
        }
    }, [props.value.supported_networks]);

    useEffect(() => {
        if (
            props.open &&
            !props.verifiedNetworksInProgress &&
            (!props.verifiedNetworksList || props.verifiedNetworksList.length === 0)
        ) {
            props.fetchVerifiedNetworks(DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }, [props.open]);

    const handleClick = () => {
        setProgress(true);
        const data = {
            id: props.value && props.value._id,
        };

        if (props.networks && props.networks.length > 0) {
            data.supportedNetworks = props.networks && props.networks.map((item) => item.value);
        }
        if (props.value && props.value._id) {
            props.updateFeature(props.value._id, data, (cb) => {
                if (!cb) {
                    setProgress(false);
                    props.hideUpdateFeatureDialog();
                    props.fetchFeatures(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
                }
            });
        } else {
            props.showMessage('Please add all fields');
        }
    };

    const selectedValue = props.value && props.value.supported_networks;

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog campaign_dialog"
            open={props.open}
            onClose={props.hideUpdateFeatureDialog}>
            <div className="mint_access campaign_dialog_section">
                <h2> Update Feature </h2>
                <div className="campaign_fields">
                    <NetworkSelectField selectedValue={selectedValue}/>
                </div>
                <Button onClick={handleClick}>
                    {progress && <CircularProgress/>}
                        Update
                </Button>
            </div>
        </Dialog>
    );
};

UpdateFeatureDialog.propTypes = {
    fetchFeatures: PropTypes.func.isRequired,
    fetchNetworks: PropTypes.func.isRequired,
    fetchVerifiedNetworks: PropTypes.func.isRequired,
    hideUpdateFeatureDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    networks: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    setNetworkValueFeature: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    updateFeature: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    verifiedNetworksInProgress: PropTypes.bool.isRequired,
    verifiedNetworksList: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.studio.updateFeatureDialog.open,
        value: state.studio.updateFeatureDialog.value,
        networks: state.studio.featureNetworkValue,

        verifiedNetworksInProgress: state.studio.verifiedNetworksList.inProgress,
        verifiedNetworksList: state.studio.verifiedNetworksList.list,
    };
};

const actionsToProps = {
    hideUpdateFeatureDialog,
    showMessage,
    fetchFeatures,
    updateFeature,
    fetchNetworks,
    setNetworkValueFeature,

    fetchVerifiedNetworks,
};

export default connect(stateToProps, actionsToProps)(UpdateFeatureDialog);
