import React from 'react';
import DataTable from '../../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../../components/CircularProgress';
import { Button } from '@material-ui/core';
import { fetchFeaturedDrops, showUpdateFeaturedDropsDialog, updateFeaturedDrops } from '../../../../actions/home';
import CopyButton from '../../../../components/CopyButton';
import { getWrapAddress } from '../../../../utils/strings';

const FeaturedDropsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeaturedDrops(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeaturedDrops(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchFeaturedDrops(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            // if (changedColumn === 'isFeatured' && direction === 'desc') {
            //     props.fetchCollections(props.skip, props.limit, changedColumn, direction, props.searchKey, 'isFeatured');
            // } else {
            props.fetchFeaturedDrops(props.skip, props.limit, changedColumn, direction, props.searchKey);
            // }
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
    };

    const handleClick = (value, verify) => {
        props.showUpdateFeaturedDropsDialog(value, verify);
    };

    const columns = [{
        name: 'code_id',
        label: 'Code ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="collection_name">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'type',
        label: 'Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="collection_name">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'minter_contract_address',
        label: 'Contract Address',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="to_value tx_hash creator_address">
                        <p>{getWrapAddress(value, 6)}</p>
                        <CopyButton data={value}/>
                    </div>
                );
            },
        },
    }, {
        name: 'unique_users',
        label: 'Unique Users',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'sender',
        label: 'Sender',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="to_value tx_hash creator_address">
                        <p>{getWrapAddress(value, 6)}</p>
                        <CopyButton data={value}/>
                    </div>
                );
            },
        },
    }, {
        name: 'admin',
        label: 'Admin',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div className="to_value tx_hash creator_address">
                        <p>{getWrapAddress(value, 6)}</p>
                        <CopyButton data={value}/>
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        {value && value.featured
                            ? <Button onClick={() => handleClick(value, 'unverify')}>
                                Un-Verify
                            </Button>
                            : <Button onClick={() => handleClick(value, 'verify')}>
                                Verify
                            </Button>}
                    </div>

                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.code_id,
                item.type,
                item.minter_contract_address,
                item.unique_users,
                item.sender,
                item.admin,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                // name={'Mint Requests'}
                options={options}/>
        </>
    );
};

FeaturedDropsTable.propTypes = {
    fetchFeaturedDrops: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showUpdateFeaturedDropsDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateFeaturedDrops: PropTypes.func.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.featuredDrops.list,
        inProgress: state.home.featuredDrops.inProgress,
        skip: state.home.featuredDrops.skip,
        limit: state.home.featuredDrops.limit,
        sortBy: state.home.featuredDrops.sortBy,
        order: state.home.featuredDrops.order,
        total: state.home.featuredDrops.count,
        searchKey: state.home.featuredDrops.searchKey,
    };
};

const actionsToProps = {
    fetchFeaturedDrops,
    updateFeaturedDrops,
    showUpdateFeaturedDropsDialog,
};

export default connect(stateToProps, actionsToProps)(FeaturedDropsTable);
