import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchCollections, setShowFeatured, showLaunchpadDialog, showMintAccessDialog } from '../../actions/home';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import CollectionsTable from './Market/CollectionsTable';
import MintAccessDialog from './Market/MintAccessDialog';
import LaunchpadDialog from './Market/LaunchpadDialog';
import Tabs from './Market/Tabs';
import Campaign from './Market/Campaign';
import AddCampaignDialog from './Market/Campaign/AddDialog';
import Quickview from './Market/Campaign/Quickview';
import DeleteCampaignDialog from './Market/Campaign/DeleteDialog';
import FeaturedPositionDialog from './Market/FeaturedPositionDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ITCCampaignsTable from './Market/ITCCampaignsTable';
import Banners from './Market/Banners';
import AddBannerDialog from './Market/Banners/AddDialog';
import BannerQuickView from './Market/Banners/QuickView';
import DeleteBannerDialog from './Market/Banners/DeleteDIalog';
import withRouter from '../../components/WithRouter';
import Studio from './Studio';
import FeaturedDropsUpdateDialog from './Market/FeaturedDrops/UpdateDialog';
import FeaturedDrops from './Market/FeaturedDrops';

class Home extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            value: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange (event, value) {
        this.setState({ value });
    }

    componentDidMount () {
        const token = localStorage.getItem('authorizationToken_nucleusConsole');
        if (token) {
            this.props.fetchCollections(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc', '', this.props.showFeatured);
        } else {
            this.props.router.navigate('/login');
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetchCollections(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc', '', this.props.showFeatured);
        }
    }

    handleClick (event) {
        if (event.target.checked) {
            this.props.fetchCollections(this.props.skip, this.props.limit, this.props.sortBy, this.props.order, this.props.searchKey, true);
        } else {
            this.props.fetchCollections(this.props.skip, this.props.limit, this.props.sortBy, this.props.order, this.props.searchKey, false);
        }

        this.props.setShowFeatured(event.target.checked);
    }

    render () {
        return (
            <div className="home">
                {localStorage.getItem('studio_user') === 'true'
                    ? <Studio />
                    : <div className="table_div">
                        <div className="header">
                            <h2> Nucleus Console</h2>
                            {/* <Button onClick={this.props.showMintAccessDialog}>Mint Access</Button> */}
                            {/* <Button onClick={this.props.showLaunchpadDialog}>Add Launchpad</Button> */}
                        </div>
                        <div className="header_section1">
                            <Tabs />
                            <div>
                                {this.props.tabValue === 'collections' &&
                            <FormControlLabel
                                className="checkbox"
                                control={
                                    <Checkbox
                                        checked={this.props.showFeatured}
                                        onChange={this.handleClick}
                                    />
                                }
                                label="Show Featured"
                            />}
                            </div>
                        </div>
                        <div className="collection_table">
                            { this.props.tabValue === 'collections'
                                ? <CollectionsTable/>
                                : this.props.tabValue === 'campaigns'
                                    ? <Campaign />
                                    : this.props.tabValue === 'itc_campaigns'
                                        ? <ITCCampaignsTable />
                                        : this.props.tabValue === 'banners'
                                            ? <Banners/>
                                            : this.props.tabValue === 'featured_drops'
                                                ? <FeaturedDrops/>
                                                : null }
                        </div>
                    </div>}
                <MintAccessDialog/>
                <LaunchpadDialog/>
                <AddCampaignDialog/>
                <Quickview/>
                <DeleteCampaignDialog/>
                <FeaturedPositionDialog />
                <AddBannerDialog/>
                <BannerQuickView/>
                <DeleteBannerDialog/>
                <FeaturedDropsUpdateDialog />
            </div>
        );
    }
}

Home.propTypes = {
    limit: PropTypes.number.isRequired,
    setShowFeatured: PropTypes.func.isRequired,
    showFeatured: PropTypes.bool.isRequired,
    showLaunchpadDialog: PropTypes.func.isRequired,
    showMintAccessDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    auth: PropTypes.string,
    fetchCollections: PropTypes.func,
    inProgress: PropTypes.bool,
    order: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
    searchKey: PropTypes.string,
    snackbar: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        inProgress: state.home.collections.inProgress,
        snackbar: state.snackbar.message,
        tabValue: state.campaign.tabValue,

        skip: state.home.collections.skip,
        limit: state.home.collections.limit,
        sortBy: state.home.collections.sortBy,
        order: state.home.collections.order,
        total: state.home.collections.count,
        searchKey: state.home.collections.searchKey,
        showFeatured: state.home.showFeatured,
    };
};

const actionToProps = {
    fetchCollections,
    showMintAccessDialog,
    showLaunchpadDialog,
    setShowFeatured,
};

export default withRouter(connect(stateToProps, actionToProps)(Home));
