import {
    IV_LIST_URL, urlIVUpdate,
    urlUpdateNetwork,
    NETOWKRS_LIST_URL,
    FEATURES_LIST_URL,
    urlUpdateFeature,
    VERIFIED_NETOWKRS_LIST_URL,
} from '../constants/url';
import Axios from 'axios';
import {
    IS_BANNER_POSITION_DIALOG_HIDE,
    IS_BANNER_POSITION_DIALOG_SHOW,
    IS_BANNER_POSITION_SET,
    IS_TRENDING_POSITION_DIALOG_HIDE,
    IS_TRENDING_POSITION_DIALOG_SHOW,
    IS_TRENDING_POSITION_SET,
    IV_DATA_UPDATE_ERROR,
    IV_DATA_UPDATE_IN_PROGRESS,
    IV_DATA_UPDATE_SUCCESS,
    IV_LIST_FETCH_ERROR,
    IV_LIST_FETCH_IN_PROGRESS,
    IV_LIST_FETCH_SUCCESS,
    SHOW_BANNER_SET,
    NETWORKS_LIST_FETCH_IN_PROGRESS,
    NETWORKS_LIST_FETCH_SUCCESS,
    NETWORKS_LIST_FETCH_ERROR,
    NETWORK_UPDATE_FETCH_IN_PROGRESS,
    NETWORK_UPDATE_FETCH_SUCCESS,
    NETWORK_UPDATE_FETCH_ERROR,
    FEATURES_LIST_FETCH_IN_PROGRESS,
    FEATURES_LIST_FETCH_SUCCESS,
    FEATURES_LIST_FETCH_ERROR,
    STUDIO_TAB_VALUE_SET,
    NETWORK_VERIFY_DIALOG_HIDE,
    NETWORK_VERIFY_DIALOG_SHOW,
    NETWORK_VALUE_FEATURE_SET,
    UPDATE_FEATURE_DIALOG_SHOW,
    UPDATE_FEATURE_DIALOG_HIDE,
    UPDATE_FEATURE_IN_PROGRESS,
    UPDATE_FEATURE_SUCCESS,
    UPDATE_FEATURE_ERROR,
    VERIFIED_NETWORKS_LIST_FETCH_ERROR,
    VERIFIED_NETWORKS_LIST_FETCH_SUCCESS,
    VERIFIED_NETWORKS_LIST_FETCH_IN_PROGRESS,
} from '../constants/studio';

export const setStudioTabValue = (value) => {
    return {
        type: STUDIO_TAB_VALUE_SET,
        value,
    };
};

export const setShowBanner = (value) => {
    return {
        type: SHOW_BANNER_SET,
        value,
    };
};

export const showBannerPositionDialog = (value) => {
    return {
        type: IS_BANNER_POSITION_DIALOG_SHOW,
        value,
    };
};

export const hideBannerPositionDialog = () => {
    return {
        type: IS_BANNER_POSITION_DIALOG_HIDE,
    };
};

export const setBannerPosition = (value) => {
    return {
        type: IS_BANNER_POSITION_SET,
        value,
    };
};

export const showTrendingPositionDialog = (value) => {
    return {
        type: IS_TRENDING_POSITION_DIALOG_SHOW,
        value,
    };
};

export const hideTrendingPositionDialog = () => {
    return {
        type: IS_TRENDING_POSITION_DIALOG_HIDE,
    };
};

export const setTrendingPosition = (value) => {
    return {
        type: IS_TRENDING_POSITION_SET,
        value,
    };
};

const fetchIVListInProgress = () => {
    return {
        type: IV_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchIVListSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: IV_LIST_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchIVListError = (message) => {
    return {
        type: IV_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchIVList = (skip, limit, sortBy, order, searchKey, banner) => (dispatch) => {
    dispatch(fetchIVListInProgress());

    const url = IV_LIST_URL(skip, limit, sortBy, order, searchKey, banner);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchIVListSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchIVListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateIVDataInProgress = () => {
    return {
        type: IV_DATA_UPDATE_IN_PROGRESS,
    };
};

const updateIVDataSuccess = (value, message) => {
    return {
        type: IV_DATA_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateIVDataError = (message) => {
    return {
        type: IV_DATA_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateIVData = (id, data, cb) => (dispatch) => {
    dispatch(updateIVDataInProgress());

    const url = urlIVUpdate(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            dispatch(updateIVDataSuccess(res.data && res.data.result, 'Collection Tag Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateIVDataError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

// Networks
export const showVerifyDialog = (value, verify) => {
    return {
        type: NETWORK_VERIFY_DIALOG_SHOW,
        value,
        verify,
    };
};

export const hideVerifyDialog = () => {
    return {
        type: NETWORK_VERIFY_DIALOG_HIDE,
    };
};

const fetchNetworksInProgress = () => {
    return {
        type: NETWORKS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchNetworksSuccess = (items, count, skip, limit, sortBy, order, searchKey) => {
    return {
        type: NETWORKS_LIST_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
        searchKey,
    };
};

const fetchNetworksError = (message) => {
    return {
        type: NETWORKS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchNetworks = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchNetworksInProgress());

    const url = NETOWKRS_LIST_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchNetworksSuccess(items, count, skip, limit, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchNetworksError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateNetworkInProgress = () => {
    return {
        type: NETWORK_UPDATE_FETCH_IN_PROGRESS,
    };
};

const updateNetworkSuccess = (value, message) => {
    return {
        type: NETWORK_UPDATE_FETCH_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateNetworkError = (message) => {
    return {
        type: NETWORK_UPDATE_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const updateNetwork = (id, data, cb) => (dispatch) => {
    dispatch(updateNetworkInProgress());

    const url = urlUpdateNetwork(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            dispatch(updateNetworkSuccess(res.data && res.data.result, 'Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateNetworkError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const fetchFeaturesInProgress = () => {
    return {
        type: FEATURES_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchFeaturesSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: FEATURES_LIST_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchFeaturesError = (message) => {
    return {
        type: FEATURES_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchFeatures = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchFeaturesInProgress());

    const url = FEATURES_LIST_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchFeaturesSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchFeaturesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const setNetworkValueFeature = (value) => {
    return {
        type: NETWORK_VALUE_FEATURE_SET,
        value,
    };
};

export const showUpdateFeatureDialog = (value) => {
    return {
        type: UPDATE_FEATURE_DIALOG_SHOW,
        value,
    };
};

export const hideUpdateFeatureDialog = () => {
    return {
        type: UPDATE_FEATURE_DIALOG_HIDE,
    };
};

const updateFeatureInProgress = () => {
    return {
        type: UPDATE_FEATURE_IN_PROGRESS,
    };
};

const updateFeatureSuccess = (value, message) => {
    return {
        type: UPDATE_FEATURE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateFeatureError = (message) => {
    return {
        type: UPDATE_FEATURE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateFeature = (id, data, cb) => (dispatch) => {
    dispatch(updateFeatureInProgress());

    const url = urlUpdateFeature(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            dispatch(updateFeatureSuccess(res.data && res.data.result, 'Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateFeatureError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const fetchVerifiedNetworksInProgress = () => {
    return {
        type: VERIFIED_NETWORKS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchVerifiedNetworksSuccess = (items, count, skip, limit, sortBy, order, searchKey) => {
    return {
        type: VERIFIED_NETWORKS_LIST_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
        searchKey,
    };
};

const fetchVerifiedNetworksError = (message) => {
    return {
        type: VERIFIED_NETWORKS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchVerifiedNetworks = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchVerifiedNetworksInProgress());

    const url = VERIFIED_NETOWKRS_LIST_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchVerifiedNetworksSuccess(items, count, skip, limit, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchVerifiedNetworksError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
