export const IV_LIST_FETCH_SUCCESS = 'IV_LIST_FETCH_SUCCESS';
export const IV_LIST_FETCH_IN_PROGRESS = 'IV_LIST_FETCH_IN_PROGRESS';
export const IV_LIST_FETCH_ERROR = 'IV_LIST_FETCH_ERROR';

export const IV_DATA_UPDATE_SUCCESS = 'IV_DATA_UPDATE_SUCCESS';
export const IV_DATA_UPDATE_IN_PROGRESS = 'IV_DATA_UPDATE_IN_PROGRESS';
export const IV_DATA_UPDATE_ERROR = 'IV_DATA_UPDATE_ERROR';

export const IS_BANNER_POSITION_SET = 'IS_BANNER_POSITION_SET';
export const IS_BANNER_POSITION_DIALOG_SHOW = 'IS_BANNER_POSITION_DIALOG_SHOW';
export const IS_BANNER_POSITION_DIALOG_HIDE = 'IS_BANNER_POSITION_DIALOG_HIDE';
export const SHOW_BANNER_SET = 'SHOW_BANNER_SET';

export const IS_TRENDING_POSITION_SET = 'IS_TRENDING_POSITION_SET';
export const IS_TRENDING_POSITION_DIALOG_SHOW = 'IS_TRENDING_POSITION_DIALOG_SHOW';
export const IS_TRENDING_POSITION_DIALOG_HIDE = 'IS_TRENDING_POSITION_DIALOG_HIDE';

export const NETWORKS_LIST_FETCH_SUCCESS = 'NETWORKS_LIST_FETCH_SUCCESS';
export const NETWORKS_LIST_FETCH_IN_PROGRESS = 'NETWORKS_LIST_FETCH_IN_PROGRESS';
export const NETWORKS_LIST_FETCH_ERROR = 'NETWORKS_LIST_FETCH_ERROR';

export const NETWORK_VERIFY_DIALOG_SHOW = 'NETWORK_VERIFY_DIALOG_SHOW';
export const NETWORK_VERIFY_DIALOG_HIDE = 'NETWORK_VERIFY_DIALOG_HIDE';

export const NETWORK_UPDATE_FETCH_SUCCESS = 'NETWORK_UPDATE_FETCH_SUCCESS';
export const NETWORK_UPDATE_FETCH_IN_PROGRESS = 'NETWORK_UPDATE_FETCH_IN_PROGRESS';
export const NETWORK_UPDATE_FETCH_ERROR = 'NETWORK_UPDATE_FETCH_ERROR';

export const FEATURES_LIST_FETCH_SUCCESS = 'FEATURES_LIST_FETCH_SUCCESS';
export const FEATURES_LIST_FETCH_IN_PROGRESS = 'FEATURES_LIST_FETCH_IN_PROGRESS';
export const FEATURES_LIST_FETCH_ERROR = 'FEATURES_LIST_FETCH_ERROR';

export const STUDIO_TAB_VALUE_SET = 'STUDIO_TAB_VALUE_SET';

export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS';
export const UPDATE_FEATURE_IN_PROGRESS = 'UPDATE_FEATURE_IN_PROGRESS';
export const UPDATE_FEATURE_ERROR = 'UPDATE_FEATURE_ERROR';

export const UPDATE_FEATURE_DIALOG_SHOW = 'UPDATE_FEATURE_DIALOG_SHOW';
export const UPDATE_FEATURE_DIALOG_HIDE = 'UPDATE_FEATURE_DIALOG_HIDE';
export const NETWORK_VALUE_FEATURE_SET = 'NETWORK_VALUE_FEATURE_SET';

export const VERIFIED_NETWORKS_LIST_FETCH_SUCCESS = 'VERIFIED_NETWORKS_LIST_FETCH_SUCCESS';
export const VERIFIED_NETWORKS_LIST_FETCH_IN_PROGRESS = 'VERIFIED_NETWORKS_LIST_FETCH_IN_PROGRESS';
export const VERIFIED_NETWORKS_LIST_FETCH_ERROR = 'VERIFIED_NETWORKS_LIST_FETCH_ERROR';
