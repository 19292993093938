import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import { connect } from 'react-redux';
import CircularProgress from '../../../../components/CircularProgress';
import { Button } from '@material-ui/core';
import withRouter from '../../../../components/WithRouter';
import { fetchNetworks, showVerifyDialog } from '../../../../actions/studio';

const Table = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchNetworks(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchNetworks(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchNetworks(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchNetworks(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        type: 'chain_name',
        label: 'Chain Name',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'coingecko_id',
        label: 'Coingecko ID',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'ibc_channels_count',
        label: 'IBC Channel Count',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'is_convertable',
        label: 'Convertable',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'is_cosmos_chain',
        label: 'Cosmos Chain',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'is_deleted_chain',
        label: 'Deleted Chain',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        {value.is_verified
                            ? <Button onClick={() => props.showVerifyDialog(value, 'unverify')}>
                            Un-Verify
                            </Button>
                            : <Button onClick={() => props.showVerifyDialog(value, 'verify')}>
                            Verify
                            </Button>}
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.chain_name,
                item.coingecko_id,
                item.ibc_channels_count ? item.ibc_channels_count : '0',
                item.is_convertable ? 'true' : 'false',
                item.is_cosmos_chain ? 'true' : 'false',
                item.is_deleted_chain ? 'true' : 'false',
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

Table.propTypes = {
    fetchNetworks: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showVerifyDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.studio.networksList.inProgress,
        list: state.studio.networksList.list,
        total: state.studio.networksList.count,
        skip: state.studio.networksList.skip,
        limit: state.studio.networksList.limit,
        sortBy: state.studio.networksList.sortBy,
        order: state.studio.networksList.order,
        searchKey: state.studio.networksList.searchKey,
    };
};

const actionToProps = {
    fetchNetworks,
    showVerifyDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(Table));
